<template>
  <v-app class="v-app-bg">
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- v-content -->
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- v-footer -->

    <!-- $store.state.user.profile = {{ $store.state.user.profile }} -->
    <v-footer
      padless
      v-if="$store.state.login && $store.state.user.profile != 'Self-Service'"
    >
      <v-row justify="center">
        <v-bottom-navigation
          class="bg-blue-grey-lighten-5 text-center"
          min-height="120px"
        >
          <v-btn
            v-for="(item, i) in footer"
            :key="i"
            class="mt-0"
          >
            <router-link :to="{ name: item.route }">
              <v-icon
                color="primary"
                size="26px"
                class="mt-1"
              >{{ item.icon }}</v-icon>
            </router-link>
            <span v-if="item.text != '...'">{{ $t("menu." + item.text) }}</span>
            <span v-if="item.text == '...'"> ... </span>
          </v-btn>
          <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
          <!-- Menu User -->
          <v-menu bottom>
            <template v-slot:activator="{ props }">
              <v-btn
                icon
                v-bind="props"
                class="mt-0"
              >
                <v-avatar
                  color="blue"
                  size="36"
                >
                  <span class="text-h5 text-white">{{
                    $store.state.user.userName.substring(0, 1)
                  }}</span>
                </v-avatar>
              </v-btn>
            </template>

            <v-list>
              <v-list-subheader>{{ $store.state.user.userName }}</v-list-subheader>
              <div>
                <v-list-item
                  v-for="(item, i) in footerUser"
                  :key="i"
                  router
                  @click="goRouter(item)"
                >
                  <template v-slot:prepend>
                    <v-icon :icon="item.icon"></v-icon>
                  </template>
                  <div>
                    <v-list-item-title v-text="$t('menu.' + item.text)"></v-list-item-title>
                  </div>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-bottom-navigation>
      </v-row>
    </v-footer>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
  </v-app>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      footer: [
        { text: "Menu", icon: "mdi-home-outline", route: "menu" },
        { text: "...", icon: "mdi-home-import-outline", route: "MenuModule" },
        { text: "Login", icon: "mdi-lock-open-outline", route: "login" },
      ],
      //
      footerUser: [
        {
          text: "My Account",
          icon: "mdi-account",
          route: "userEdit",
          option: "myAccount",
        },
        {
          text: "Change Password",
          icon: "mdi-key",
          route: "userChangePassword",
          option: "",
        },
        {
          text: "Logout",
          icon: "mdi-logout",
          route: "login",
          option: "logout",
        },
      ],
    };
  },
  //////////
  methods: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  JS     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// clear
    logout() {},

    goRouter(item) {
      sessionStorage.userId = this.$store.state.user.userId;
      sessionStorage.pageOrigin = "footer";
      sessionStorage.routerOption = "footer";
      //
      this.$router.push({
        name: item.route,
      });
    },
  },
  ///////////
  created() {},

  ////////////
  mounted() {},
};
</script>

<style lang="scss">
@import "@/css/scss/style.scss";
</style>
