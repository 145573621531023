import { createRouter, createWebHistory } from 'vue-router'
//

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE ACCESS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import Login from '@/app/1_access/views/login/Login.vue'
import ForgotMyPassword from '@/app/1_access/views/login/ForgotMyPassword.vue'
import ResetPassword from '@/app/1_access/views/login/ResetPassword.vue'
import NewAccountActivation from '@/app/1_access/views/login/NewAccountActivation.vue'
import EmailValidation from '@/app/1_access/views/login/EmailValidation.vue'

// lazy
const User = () => import('@/app/1_access/views/user/User.vue')
const UserAccess = () => import('@/app/1_access/views/user/UserAccess.vue')
const UserLogin = () => import('@/app/1_access/views/user/UserLogin.vue')
const UserSelect = () => import('@/app/1_access/views/user/UserSelect.vue')
const UserChangePassword = () => import('@/app/1_access/views/user/UserChangePassword.vue')

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE COMMON
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import Menu from '@/app/2_common/views/Menu.vue'
import MenuModule from '@/app/2_common/views/MenuModule.vue'

const Orientation = () => import('@/app/2_common/views/Orientation.vue')
const error404 = () => import('@/app/2_common/views/error404.vue')
const ImageGallery = () => import('@/app/2_common/views/ImageGallery.vue')
const FollowUp = () => import('@/app/2_common/views/FollowUp.vue')
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE COMPANY
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const EnterpriseAddress = () => import('@/app/3_company/views/enterprise/EnterpriseAddress.vue')

// const Foo = () => const ('./Foo.vue')
const Customer = () => import('@/app/3_company/views/customer/Customer.vue')
const CustomerContact = () => import('@/app/3_company/views/customer/CustomerContact.vue')
const CustomerAddress = () => import('@/app/3_company/views/customer/CustomerAddress.vue')
const CustomerBank = () => import('@/app/3_company/views/customer/CustomerBank.vue')
const CustomerCommercial = () => import('@/app/3_company/views/customer/CustomerCommercial.vue')
const CustomerSelect = () => import('@/app/3_company/views/customer/CustomerSelect.vue')

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE STOCK
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE PURCHASE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE CONSUMER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Deliver = () => import('@/app/6_consumer/views/Deliver.vue')
const DeliverSelect = () => import('@/app/6_consumer/views/DeliverSelect.vue')

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE COMMERCIAL
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  MODULE DELIVERY
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Vue.use(Router)
// // export default new Router({ ... comentado depois router.beforeEach
// const router = new Router({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes: [


const routes = [
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  MODULE ACCESS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // login
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      auth: false,
      title: 'Login'
    }
  },
  {
    path: '/',
    name: 'forgotMyPassword',
    component: ForgotMyPassword,
    meta: {
      auth: false,
      title: 'Forgot My Password'
    }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: ResetPassword,
    meta: {
      auth: false,
      title: 'Reset Password'
    }
  },

  {
    path: '/newAccountActivation',
    name: 'newAccountActivation',
    component: NewAccountActivation,
    meta: {
      auth: false,
      title: 'New Account Activation'
    }
  },

  {
    path: '/emailValidation',
    name: 'emailValidation',
    component: EmailValidation,
    meta: {
      auth: false,
      title: 'Email Validation'
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // user
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: '/user',
    name: 'userEdit', // deixar  'Edit'  antes do  'New' para prever retorno pelo botão do navegador
    component: User,
    meta: {
      auth: true,
      title: 'Edit User'
    }
  },
  {
    path: '/user',
    name: 'userNewAccount',
    component: User,
    meta: {
      auth: false, // new login
      title: 'New Account'
    }
  },
  {
    path: '/user',
    name: 'user',
    component: User,
    meta: {
      auth: true,
      title: 'New User'
    }
  },

  {
    path: '/access',
    name: 'userAccess',
    component: UserAccess,
    meta: {
      auth: true,
      title: 'User Accesses'
    }
  },
  {
    path: '/logins',
    name: 'userLogin',
    component: UserLogin,
    meta: {
      auth: true,
      title: 'User Logins'
    }
  },
  {
    path: '/users',
    name: 'UserSelect',
    params: {
      recent: false
    },
    component: UserSelect,
    meta: {
      auth: true,
      title: 'Users'
    }
  },
  {
    path: '/userChangePassword',
    name: 'userChangePassword',
    component: UserChangePassword,
    meta: {
      auth: false, // forgot
      title: 'Change Password'
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  MODULE COMMON
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: '/menu',
    name: 'menu',
    component: Menu,
    meta: {
      auth: true,
      title: 'Menu'
    }
  },

  {
    path: '/menu_',
    name: 'MenuModule',
    component: MenuModule,
    meta: {
      auth: true,
      title: 'Menu'
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Orientation
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: '/orientation',
    name: 'Orientation',
    component: Orientation,
    meta: {
      title: 'Orientation'
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // error404
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  {
    path: "/:catchAll(.*)",
    name: "error404",
    component: error404,
    meta: {
      auth: false,
      title: "Page Not Found",
    },
  },


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ImageGallery
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: '/images',
    name: 'imageGallery',
    component: ImageGallery,
    meta: {
      auth: true,
      title: 'Images'
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Log
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: '/follow-up',
    name: 'FollowUp',
    component: FollowUp,
    meta: {
      auth: true,
      title: 'Follow-up'
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  MODULE COMPANY
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // enterprise
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: '/address',
    name: 'enterpriseAddress',
    component: EnterpriseAddress,
    meta: {
      auth: true,
      title: 'Enterprise Addresses'
    }
  },


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // customer
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: '/customer',
    name: 'customerEdit', // deixar  'Edit'  antes do  'New' para prever retorno pelo botão do navegador
    component: Customer,
    meta: {
      auth: true,
      title: 'Edit Customer'
    }
  },
  {
    path: '/customer',
    name: 'customer',
    component: Customer,
    meta: {
      auth: true,
      title: 'New Customer'
    }
  },
  {
    path: '/contact',
    name: 'customerContact',
    component: CustomerContact,
    meta: {
      auth: true,
      title: 'Customer Contacts'
    }
  },
  {
    path: '/address',
    name: 'customerAddress',
    component: CustomerAddress,
    meta: {
      auth: true,
      title: 'Customer Addresses'
    }
  },
  {
    path: '/bank',
    name: 'customerBank',
    component: CustomerBank,
    meta: {
      auth: true,
      title: 'Customer Banks'
    }
  },
  {
    path: '/customer_commercial',
    name: 'customerCommercial',
    component: CustomerCommercial,
    meta: {
      auth: true,
      title: 'Customer Commercial'
    }
  },

  {
    path: '/customers',
    name: 'CustomerSelect',
    params: {
      recent: false
    },
    component: CustomerSelect,
    meta: {
      auth: true,
      title: 'Customers'
    }
  },


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  MODULE CONSUMER
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: '/deliver',
    name: 'deliverEdit',
    component: Deliver,
    meta: {
      auth: true,
      title: 'Edit Delivery Order'
    }
  },

  {
    path: '/app',
    name: 'deliver',
    component: Deliver,
    meta: {
      auth: false,
      title: 'Delivery'
    }
  },


  {
    path: '/delivers',
    name: 'DeliverSelect',
    params: {
      recent: false
    },
    component: DeliverSelect,
    meta: {
      auth: true,
      title: 'Delivery Orders'
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  MODULE COMMERCIAL
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  MODULE DELIVERY
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////













];

//   scrollBehavior() {
//     return window.scrollTo({
//       top: 0,
//       behavior: 'smooth'
//     })
//   }
// })

// router.beforeEach((to, from, next) => {
//   // console.log(to)
//   document.title = to.meta.title
//   next()
// })

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
//
export default router;
